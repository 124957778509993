import React, {useState, useEffect} from 'react';
import {graphql, Link} from 'gatsby';
import {osName, BrowserView, MobileView} from 'react-device-detect';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {Layout} from '../components/Layout';
import {Button, ButtonVariant} from '../components/Button';
import PageWidth from '../components/PageWidth';
import SEO from '../components/SEO';
import Colours from '../constants/colours';
import Typography from '../constants/typography';
import {getPageData} from '../utils';
import PlatformDownloadButton from '../components/PlatformDownloadButton';
import {plansPageQuery} from './__generated__/plansPageQuery';
import {OperatingSystem} from '../components/PlatformDownloadButton';
import {
  sendStudioDownloadEvent,
  sendStudioDownloadPromptEvent,
} from '../utils/countly';
import DownloadModal from '../components/DownloadModal';
import {PlanSelector} from '../components/PlanSelector';
import {Spacer} from '../styles/page';
import {PremiumSection} from '../components/PremiumSection';
import {PageProps, PlanBillingType} from '../types';

const PlansPage = (props: PageProps<plansPageQuery>) => {
  const data = getPageData(props.data);
  const requirementsData = props.data.systemRequirements.edges[0].node;
  const [selectedOS, setSelectedOS] = useState<OperatingSystem>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanBillingType>('monthly');
  const [selectedPageData, setSelectedPageData] = useState({
    subheading: data.subheadingMonthly,
    url: `/subscription/payment/?${props.pageContext.plans.monthly.id}`,
  });

  const download = (platform: OperatingSystem) => {
    sendStudioDownloadEvent(platform.os);
    window.location.assign(platform.url);
    setSelectedOS(null);
  };

  const showDownloadModal = (platform: OperatingSystem) => {
    sendStudioDownloadPromptEvent(platform.os);
    setSelectedOS(platform);
  };

  useEffect(() => {
    const pageData = {
      subheading: data.subheadingMonthly,
      url: `/subscription/payment/?planId=${props.pageContext.plans.monthly.id}`,
    };

    setSelectedPageData(pageData);
  }, [selectedPlan]);

  const handleSwitchPlan = (plan: PlanBillingType) => {
    setSelectedPlan(plan);
  };

  const handleLoadPaymentForm = () => {
    window.location.assign(selectedPageData!.url);
  };

  return (
    <Layout>
      <SEO title={data.pageTitle} />
      <GreenSection>
        <PageWidth>
          <Heading>{data.heading}</Heading>
          <Subheading>{selectedPageData.subheading}</Subheading>
          <SmallPrint>
            See our <Link to="/cancellation-policy">cancellation policy </Link>
            for details. Promotion is only available to new subscribers
          </SmallPrint>
        </PageWidth>
      </GreenSection>
      <GreySection>
        <PageWidth>
          <PlanSelectorContainer>
            <PlanSelector
              onSelectPlanType={handleSwitchPlan}
              pricing={{
                monthly: {price: '7.99', currencySymbol: '£', term: 'mo'},
              }}
            />
          </PlanSelectorContainer>
          <Spacer />
          <Button
            data-test-id="choose-plan"
            variant={ButtonVariant.PURPLE}
            onClick={handleLoadPaymentForm}
            style={{display: 'block', margin: 'auto', borderRadius: '20px'}}
          >
            Start 30 day free trial
          </Button>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
        </PageWidth>
      </GreySection>
      <PremiumSection />
      <GreySection>
        <PageWidth>
          <DownloadSection>
            <DownloadSectionContent>
              <Subtitle>{data.sectionSubheading}</Subtitle>
              <Title>{data.sectionHeading}</Title>
              <BrowserView>
                <PlatformDownloadButton
                  os={osName}
                  handleClick={(os) => showDownloadModal(os)}
                />
              </BrowserView>
              <MobileView>
                <Paragraph>{data.mobileDownloadMessage}</Paragraph>
              </MobileView>
            </DownloadSectionContent>
            <ImageWrapper>
              <Img fluid={data.sectionImage.fluid} />
            </ImageWrapper>
          </DownloadSection>
        </PageWidth>
      </GreySection>
      {selectedOS !== null && (
        <DownloadModal
          handleDownload={() => download(selectedOS)}
          handleClose={() => setSelectedOS(null)}
          systemRequirements={{
            requirements: {
              macOS: requirementsData.systemRequirementsMacOs,
              windows: requirementsData.systemRequirementsWindows,
              other: requirementsData.systemRequirementsOther,
            },
          }}
        />
      )}
    </Layout>
  );
};

const PlanSelectorContainer = styled.div`
  transform: translateY(-80px);
`;

const Section = styled.section`
  text-align: center;
`;

const DownloadSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  background-color: ${Colours.grey[100]};
  padding: 8rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }
`;

const DownloadSectionContent = styled.div``;

const Title = styled.h1`
  font-size: ${Typography.title.desktop.fontSize};
  line-height: ${Typography.title.desktop.lineHeight};
  margin: 1rem 0 2rem 0;
`;

const Subtitle = styled.h2`
  color: ${Colours.grey[600]};
`;

const GreenSection = styled(Section)`
  text-align: left;
  background-color: ${Colours.green};
  padding: 10rem 0;
  @media (max-width: 900px) {
    padding: 4rem 0;
  }
`;

const Heading = styled.h1`
  color: ${Colours.white};
  font-size: ${Typography.title.desktop.fontSize};

  @media (max-width: 800px) {
    font-size: ${Typography.featureTitle.mobile.fontSize};
  }
`;

const Subheading = styled.p`
  font-size: 1.7rem;
  max-width: 750px;
  margin-top: 1rem;
  line-height: 1.4;
  color: ${Colours.white};
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
`;

const SmallPrint = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: ${Colours.white};

  > a {
    color: ${Colours.white};
    font-size: 1rem;
    border-bottom: 1px solid ${Colours.white};
  }
`;

const GreySection = styled.section`
  background-color: ${Colours.grey[100]};
`;

export const ImageWrapper = styled.div`
  width: 100%;
`;

export const pageQuery = graphql`
  query plansPageQuery {
    en: allContentfulAmpifyWebSubscriptionPlans(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          pageTitle
          metaKeywords
          metaDescription
          heading
          subheading
          subheadingMonthly
          subheadingYearly
          freeFeatures
          registeredFeatures
          premiumFeatures
          betaText
          betaButtonText
          betaButtonUrl
          sectionSubheading
          sectionHeading
          sectionButtonText
          sectionButtonUrl
          sectionImage {
            title
            fluid(maxWidth: 500, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mobileDownloadMessage
        }
      }
    }
    systemRequirements: allContentfulAmpifyWebStudioCustom(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          systemRequirementsMacOs
          systemRequirementsWindows
          systemRequirementsOther
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
        buildEnv
      }
    }
  }
`;

export default PlansPage;
