import React from 'react';
import styled from 'styled-components';
import {Modal} from '@focusritegroup/web-ui/';
import {Button as AmpifyButton} from '../components/Button';
import ExternalLink from './ExternalLink';
import {Subtitle} from '../styles/product';
import Typography from '../constants/typography';
import Colours from '../constants/colours';
import {SystemRequirementProps} from './SystemRequirements/SystemRequirements';

interface DownloadModalProps {
  handleClose(): void;
  handleDownload(): void;
  systemRequirements: SystemRequirementProps;
}

const DownloadModal = (props: DownloadModalProps) => {
  const [agreeToLicence, setAgreeToLicence] = React.useState(false);
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal
      maxWidth="33%"
      handleClose={() => {
        setAgreeToLicence(false);
        props.handleClose();
      }}
      frameBackgroundRgba={Colours.modalBackground}
    >
      <ModalContainer>
        <DownloadTitle>Download Ampify Studio</DownloadTitle>
        <SystemRequirementsContainer>
          <Subtitle>Minimum System Requirements</Subtitle>
          <Span as="p">{props.systemRequirements.requirements.windows}</Span>
          <Span as="p">{props.systemRequirements.requirements.macOS}</Span>
          <Span as="p">{props.systemRequirements.requirements.other}</Span>
        </SystemRequirementsContainer>
        <Subtitle>Licence Agreement</Subtitle>
        <Span>
          Ampify Studio includes access to sounds from the Ampify Sounds
          collection. Ampify Sounds are subject to the terms found in our{' '}
        </Span>
        <ExternalLink
          title="Licence Agreement"
          href="https://ampifymusic.com/soundpacks_legal/"
          classNames={['licence_external_link']}
        >
          <Span>Licence Agreement</Span>
        </ExternalLink>
        <CheckboxContainer>
          <Checkbox
            data-test-id="agree-to-licence-checkbox"
            type="checkbox"
            onChange={() => {
              setAgreeToLicence(checkboxRef.current.checked);
            }}
            checked={agreeToLicence}
            ref={checkboxRef}
          />
          <CheckboxText>
            I agree to the Ampify Sounds Licence Agreement
          </CheckboxText>
        </CheckboxContainer>

        <DownloadButton
          data-test-id="licence-download-button"
          title="Download"
          disabled={!agreeToLicence}
          onClick={props.handleDownload}
        >
          Download
        </DownloadButton>
      </ModalContainer>
    </Modal>
  );
};

const DownloadTitle = styled(Subtitle)`
  font-size: ${Typography.heading.desktop.fontSize};
  line-height: ${Typography.heading.desktop.lineHeight};

  @media (max-width: 800px) {
    font-size: ${Typography.heading.mobile.fontSize};
    line-height: ${Typography.heading.mobile.lineHeight};
  }
`;

const SystemRequirementsContainer = styled.div`
  margin-bottom: 1.2rem;
`;

const CheckboxContainer = styled.label`
  display: grid;
  grid-template-columns: 24px 1fr;
  margin: 1.2rem 0;
  place-contents: center;
`;

const Checkbox = styled.input`
  padding: 0;
  align-self: center;
`;

const CheckboxText = styled.div`
  font-size: ${Typography.body.desktop.fontSize};
  line-height: ${Typography.body.desktop.lineHeight};
  color: ${Typography.body.color};
  align-self: center;
  padding-top: 3px;

  @media (max-width: 800px) {
    font-size: ${Typography.body.mobile.fontSize};
    line-height: ${Typography.body.mobile.lineHeight};
  }
`;

const Span = styled.span`
  font-size: ${Typography.body.desktop.fontSize};
  line-height: ${Typography.body.desktop.lineHeight};
  color: ${Typography.body.color};

  @media (max-width: 800px) {
    font-size: ${Typography.body.mobile.fontSize};
    line-height: ${Typography.body.mobile.lineHeight};
  }
`;

const ModalContainer = styled.div`
  color: ${Typography.body.color};
`;

const DownloadButton = styled(AmpifyButton)`
  margin: 1.2rem;
`;

export default DownloadModal;
