import React from 'react';
import styled from 'styled-components';
import Colours from '../constants/colours';

interface PlatformDownloadButtonProps {
  os: string;
  handleClick: (os: OperatingSystem) => void;
}

export interface OperatingSystem {
  os: string;
  text: string;
  url: string;
  icon(fillColour: string): JSX.Element;
}

interface PlatformTypes {
  windows: OperatingSystem;
  mac: OperatingSystem;
}

const PlatformDownloadButton = (props: PlatformDownloadButtonProps) => {
  const [showOptions, setShowOptions] = React.useState(false);

  // these paths will not work locally so they're hardcoded to production urls
  // for the latest builds
  const winUrl = 'https://ampifymusic.com/ampify-studio/win';
  const macUrl = 'https://ampifymusic.com/ampify-studio/osx';

  const displayOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowOptions(!showOptions);
  };

  const platforms: PlatformTypes = {
    windows: {
      os: 'windows',
      text: 'Download for PC',
      url: winUrl,
      icon: (fillColour: string) => (
        <WindowsIcon
          width="23px"
          height="23px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23 23"
        >
          <path
            fill={fillColour}
            fillRule="nonzero"
            d="M0 19.66864l9.2799 1.34405v-8.87832H0v7.53427zm0-8.80254h9.2799V1.98684L0 3.33089v7.53521zm10.5482 10.33045L23 23V12.13437H10.5482v9.06218zm0-19.39403v9.06358H23V0L10.5482 1.80252z"
          />
        </WindowsIcon>
      ),
    },
    mac: {
      os: 'macOS',
      text: 'Download for Mac',
      url: macUrl,
      icon: (fillColour: string) => (
        <MacOSIcon
          width="21px"
          height="26px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 26"
        >
          <g fill={fillColour} fillRule="nonzero">
            <path d="M17.22309 13.82127c-.03192-3.29199 2.61575-4.8709 2.73414-4.95066-1.48803-2.23287-3.80628-2.53925-4.63137-2.57412-1.97134-.20548-3.84848 1.19171-4.84857 1.19171-.99802 0-2.54318-1.1616-4.1779-1.13202-2.15046.03276-4.13261 1.28363-5.23924 3.25925-2.23333 3.97606-.57133 9.86858 1.60487 13.0956 1.0639 1.57732 2.33267 3.35221 3.99724 3.28724 1.60435-.06498 2.20965-1.06493 4.14908-1.06493 1.93943 0 2.484 1.06493 4.181 1.03165 1.7253-.03222 2.81906-1.60744 3.87422-3.1911 1.2214-1.83247 1.72428-3.60682 1.75413-3.69663-.03757-.01796-3.36312-1.32535-3.3976-5.25599zM14.72844 3.72056C15.6536 2.73441 16.28042 1.36413 16.10995 0c-1.33512.04737-2.95129.77916-3.90988 1.76437-.8588.87436-1.6097 2.26643-1.40848 3.60593 1.4894.10136 3.00847-.66548 3.93685-1.64974z" />
          </g>
        </MacOSIcon>
      ),
    },
  };

  let selected: OperatingSystem = platforms.mac;
  let dropdown: OperatingSystem = platforms.windows;

  // react-device-detect uses "none" during build
  if (props.os && props.os !== 'Mac OS' && props.os !== 'none') {
    selected = platforms.windows;
    dropdown = platforms.mac;
  }

  return (
    <Wrapper>
      <Button
        data-test-id="download-studio"
        onClick={() => props.handleClick(selected)}
        title={selected.text}
        id={selected.os}
      >
        <IconWrapper>{selected.icon(Colours.white)}</IconWrapper>
        <Text data-test-id="download-button-text">{selected.text}</Text>
        <Selector onClick={displayOptions}>
          <DropdownArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 8">
            <path fill={Colours.white} d="M8.5 7.93333L17 0H0z" />
          </DropdownArrow>
        </Selector>
      </Button>
      <Dropdown
        shouldShowOptions={showOptions}
        onClick={() => props.handleClick(dropdown)}
        title={dropdown.text}
        data-test-id="download-dropdown-option"
      >
        <IconWrapper>{dropdown.icon(Colours.blue[500])}</IconWrapper>
        {dropdown.text}
      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: ${Colours.white};
  font-size: 1.2rem;
  cursor: pointer;
  background-color: ${Colours.blue[500]};
`;

const IconWrapper = styled.div`
  margin: 0.4rem 1.2rem 0 1.4rem;
`;

const Text = styled.div`
  /** em-square correction */
  transform: translateY(2px);
  padding-right: 2rem;
`;

interface DropdownProps {
  shouldShowOptions: boolean;
}

const Dropdown = styled.div<DropdownProps>`
  display: ${(p) => (p.shouldShowOptions ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  font-size: 1.2rem;
  background-color: ${Colours.white};
  color: ${Colours.blue[500]};
  padding-top: 0.8rem;
  padding-bottom: calc(0.7rem - 4px);
  cursor: pointer;

  &:hover {
    background-color: ${Colours.grey[200]};
  }
`;

const DropdownArrow = styled.svg`
  width: 18px;
  height: 8px;
`;

const Selector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colours.blue[600]};
  padding: 1.4rem 1.2rem;
`;

const WindowsIcon = styled.svg`
  width: 23px;
  height: 23px;
`;

const MacOSIcon = styled.svg`
  width: 21px;
  height: 26px;
`;

export default PlatformDownloadButton;
