import styled from 'styled-components';
import {Button} from '../components/Button';
import Colours from '../constants/colours';
import Typography from '../constants/typography';

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: center;
  align-content: center;
  padding: 9em 0;
  width: 100%;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const ProFeaturesWrapper = styled(Wrapper)`
  grid-template-columns: 50% 50%;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const HardwareWrapper = styled(ProFeaturesWrapper)``;

export const HardwareBackground = styled.div`
  background-color: ${Colours.grey[100]};
`;

export const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  margin-top: 3em;
  align-self: center;
`;

export const Subtitle = styled.h4`
  font-size: ${Typography.subtitle.desktop.fontSize};
  line-height: ${Typography.subtitle.desktop.lineHeight};
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    font-size: ${Typography.subtitle.mobile.fontSize};
    line-height: ${Typography.subtitle.mobile.lineHeight};
  }
`;

export const Title = styled.h2`
  font-size: ${Typography.title.desktop.fontSize};
  line-height: ${Typography.title.desktop.lineHeight};
  margin-bottom: 0.25em;

  @media (max-width: 800px) {
    font-size: ${Typography.title.mobile.fontSize};
    line-height: ${Typography.title.mobile.lineHeight};
  }
`;

export const Paragraph = styled.p`
  font-size: ${Typography.body.desktop.fontSize};
  line-height: ${Typography.body.desktop.lineHeight};
  color: ${Typography.body.color};

  @media (max-width: 800px) {
    font-size: ${Typography.body.mobile.fontSize};
    line-height: ${Typography.body.mobile.lineHeight};
  }
`;

export const DownloadButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DownloadIcon = styled.svg`
  width: 0.8em;
  height: 0.8em;
  transform: translateY(0.1em);
  margin-right: 0.5em;
  fill: #fff;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2rem;

  a {
    margin-right: 1em;
    border-bottom: none;
  }

  a:hover {
    border-bottom: none;
  }
`;
