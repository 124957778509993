import React, {FunctionComponent} from 'react';
import {Spacer} from '../../styles/page';
import PageWidth from '../PageWidth';
import {BlackSection, Heading} from './PremiumSection.styles';
import {PremiumSectionIcons} from './PremiumSectionIcons';

export const PremiumSection: FunctionComponent = () => {
  return (
    <BlackSection>
      <PageWidth>
        <Heading>Why go premium?</Heading>
        <Spacer />
        <PremiumSectionIcons />
      </PageWidth>
    </BlackSection>
  );
};
