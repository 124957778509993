import React from 'react';
import {
  PremiumDetails,
  PremiumHeader,
  PremiumIcon,
  PremiumItem,
  PremiumWrapper,
} from './PremiumSection.styles';
import {features} from './features';

export const PremiumSectionIcons = () => {
  const premiumFeatures = features();

  return (
    <PremiumWrapper>
      {features().map((feature) => (
        <PremiumItem key={feature.headline}>
          {feature.icon()}
          <PremiumHeader>{feature.headline}</PremiumHeader>
          <PremiumDetails>{feature.details}</PremiumDetails>
        </PremiumItem>
      ))}
    </PremiumWrapper>
  );
};
